<template>
  <div class="achievements-container">
    <div class="achievements-side">
      <Achievement
        @requestClick="requestClick2"
        ref="Achievement"
        @requestAll="requestAll1"
      ></Achievement>
      <Include  ref="Include" @requestClick="requestClick" @requestAll="requestAll2"></Include>
      <Year ref="Year" @requestClick="requestClick1" @requestAll="requestAll3"></Year>
    </div>
    <div class="content-container">
      <div class="resultNum">
        检测到<span>{{ listData.total }}</span
        >条结果
      </div>
      <AchievementItem
        :itemList="listData.list"
        @goDetails="goDetails"
      ></AchievementItem>
      <div class="no-results" v-show="+listData.total === 0">
        <img :src="require('@/assets/qietu/results.png')" alt="" />
        <p>暂无成果</p>
      </div>
      <Page
        v-show="listData.total > 8"
        show-total
        :total="listData.total > 8 * 300 ? 8 * 300 : +listData.total"
        :current="listData.pageNum"
        :page-size="8"
        :styles="{ textAlign: 'center', marginTop: '20px' }"
        @on-change="pageChange"
      >
      </Page>
      <Spin size="large" fix v-show="spinShow"></Spin>
    </div>
  </div>
</template>

<script>
import Year from "../components/Year.vue";
import Include from "../components/Include.vue";
import Achievement from "../components/Achievement.vue";
import AchievementItem from "@/components/pager/AchievementItem.vue";
export default {
  components: {
    Year,
    Include,
    Achievement,
    AchievementItem,
  },
  props: ["value"],
  data() {
    return {
      listData: {},
      params: {
        articleTypes: "0,1,2,3,4",
        types: "0,1,2,4",
        pageNo: 1,
        pageSize: 8,
        paramStr: this.value,
      },
      spinShow: false,
      articleTypeNameOBj:{
          0:"论文",
          1:"专利",
          2:"专著",
          3:"科技报告",
          4:"标准",
          5:'资讯',
          6:'报告',
          7:'视频',
          8:'会议',
          9:'课程',
      }
    };
  },
  created() {
    this.getSearchList(this.params);
  },
  watch: {
    value(newVal) {
      // 论文列表
      this.getSearchList({
        ...this.params,
        paramStr: newVal,
      }); // 当地址栏变化就调用接口
      // console.log(this.$refs.Achievement)
      // console.log(this.$refs.Include)
      // console.log(this.$refs.Year)
      console.log(newVal,'打印111')
      this.$refs.Achievement.getArticleCount({
        articleTypes: "0,1,2,3,4",
        types: "0,1,2,4",
        paramStr: newVal,
      })
      this.$refs.Include.getArticleCount({
        articleTypes: "0,1,2,3,4",
        types: "0,1,2,4",
        paramStr: newVal,
      }); // 当地址栏变化就调用接口
      this.$refs.Year.getArticleCount({
        articleTypes: "0,1,2,3,4",
        types: "0,1,2,4",
        paramStr: newVal,
      });
    },
  },
  methods: {
    async getSearchList(data) {
        this.spinShow = true;
        let newparamsObj = {
                "pageNo": data.pageNo,
                "pageSize": data.pageSize,
                "queryParam": {
                    "configMap": {
                        "must": [
                            {
                                "queryValue": data.paramStr,
                                "esField": "all"
                            }
                        ]
                    },
                    otherQueryParams: {
                        platformId: 3,
                    },
                }
            }
        for (let key in data) {
            if (
                [
                    // 'articleTypes',
                    // 'types',
                    'articleType',
                    'journalsYear',
                    'dataStyle',
                    'type'
                ].includes(key) &&
                (data[key] || data[key] === 0)
            ) {
                let json = {
                    'articleTypes':'articleType',
                    'articleType':'articleType',
                    'types':'type',
                    'type':'type',
                    'journalsYear':'journalsYear',
                    'dataStyle':'common.dataStyle'
                };
                newparamsObj.queryParam.configMap.must.push({
                    queryValue: ['articleTypes','types','type','articleType'].includes(key) ? (data[key]+'').split(',') : data[key],
                    esField: json[key],
                });
            }
        }
       this.$apis.searchServe.searchArticle(newparamsObj).then(res=>{
            this.listData = res.data.data
            this.listData.list =  res.data.data.list.map(val=>{
              function authorsfirst(arr,type){
                if(Array.isArray(arr)){
                    return arr.find(val=>{
                        return val.type === type
                    })
                }else{
                    return
                }
              
              }
              return {
                ...val,
                articleTypeName:this.articleTypeNameOBj[val.articleType],
                firstAuthor:authorsfirst(val.authors,'first') ? authorsfirst(val.authors,'first').name : '',
                othorAuthor:authorsfirst(val.authors,'other') ? authorsfirst(val.authors,'other').name : '',
                communicationAuthor:authorsfirst(val.authors,'corresponding') ? authorsfirst(val.authors,'corresponding').name : '',
                articleId:val.id

              }
            })
            this.spinShow = false;
        })
    },
    pageChange(page) {
      this.getSearchList({
        ...this.params,
        pageNo: page,
      });
    },
    goDetails(item) {
      let url;
      if (item.articleType === 0) {
        if (item.type === 0) {
          url = this.$router.resolve({
            path: "paperDetail",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 1) {
          url = this.$router.resolve({
            path: "conferencepapers",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 2) {
          url = this.$router.resolve({
            path: "conferenceppt",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 4) {
          url = this.$router.resolve({
            path: "conferenceother",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        }
      } else if (item.articleType === 1) {
        url = this.$router.resolve({
          path: "patentdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 2) {
        url = this.$router.resolve({
          path: "monographdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 3) {
        url = this.$router.resolve({
          path: "reportdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 4) {
        url = this.$router.resolve({
          path: "standarddetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 5 && item.type === 0) {
        url = this.$router.resolve({
          path: "informationdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
            id: item.articleId,
          },
        });
      }
      window.open(url.href, "_blank");
    },
    requestClick(item) {
      this.params.dataStyle = item.id;
      this.getSearchList({
        ...this.params,
      });
    },
    requestAll1() {
      this.params.articleTypes = "0,1,2,3,4";
      this.params.types = "0,1,2,4";
      delete this.params.articleType;
      delete this.params.type;
      this.getSearchList({
        ...this.params,
        paramStr: this.value,
      });
    },
    requestAll2() {
      delete this.params.dataStyle;
      this.getSearchList({
        ...this.params,
        paramStr: this.value,
      });
    },
    requestAll3() {
      delete this.params.journalsYear;
      this.getSearchList({
        ...this.params,
        paramStr: this.value,
      });
    },
    requestClick1(item) {
      this.params.journalsYear = item.name;
      this.getSearchList({
        ...this.params,
        paramStr: this.value,
      });
    },
    requestClick2(item) {
      delete this.params.articleTypes;
      delete this.params.types;
      if (item.value === "0_0") {
        // 期刊论文
        this.params.type = 0;
        this.params.articleType = 0;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if (item.value === "0_1") {
        this.params.types = "1,2";
        this.params.articleType = 0;
        delete this.params.type;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if (item.value === "0_4") {
        this.params.type = 4;
        delete this.params.articleType;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if (item.value === "1") {
        delete this.params.type;
        this.params.articleType = 1;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if (item.value === "2") {
        // 专著
        delete this.params.type;
        this.params.articleType = 2;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if (item.value === "3") {
        delete this.params.type;
        this.params.articleType = 3;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if (item.value === "4") {
        delete this.params.type;
        this.params.articleType = 4;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if (item.value === '0'){
        // 论文
        delete this.params.type;
        this.params.articleType = 0;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      } else if(item.value === '5'){
        // 资讯 10/28 新增
        delete this.params.type;
        this.params.articleType = 5;
        this.getSearchList({
          ...this.params,
          paramStr: this.value,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.achievements-container {
  width: 1440px;
  display: flex;
  .content-container {
    position: relative;
    padding: 29px;
    margin-left: 39px;
    width: 1140px;
    border: 1px dotted #999999;
    border-radius: 12px;
    background-color: #fff;
    .resultNum {
      font-size: 15px;
      color: #999999;
      font-weight: 400;
      span {
        color: #00a4ff;
      }
      margin-bottom: 14px;
    }
    .no-results {
      margin: 0 auto;
      width: 500px;
      // height: 300px;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
