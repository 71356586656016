<template>
  <div class="side-box-container">
    <h2 class="title">成果类型</h2>
    <div
      class="box"
      v-for="(v, i) in achievementList"
      ref="sideLi"
      :key="i"
      :class="{ active: i === index }"
      @click="handleClick(v, i)"
    >
      <span class="name">{{ v.valueName }}</span>
      <span class="num">({{ v.score }})</span>
    </div>
    <Spin size="large" fix :show="loadingFlag" v-if="loadingFlag"></Spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: -1,
      achievementList: [],
      loadingFlag:false
    };
  },
  watch: {
    "$route.query"(newVal) {
      // 论文列表
      this.getArticleCount({
        articleTypes: "0,1,2,3,4",
        types: "0,1,2,4",
        paramStr: newVal.paramStr,
      }); // 当地址栏变化就调用接口
    },
  },
  created() {
    this.getArticleCount({
      articleTypes: "0,1,2,3,4",
      types: "0,1,2,4",
      paramStr: this.$route.query.paramStr,
    });
  },
  methods: {
    handleClick(item, i) {
      this.index = i;
      let element = this.$refs.sideLi[this.index];
      if (element.classList.contains("active")) {
        element.classList.remove("active");
        this.$emit("requestAll");
      } else {
        element.classList.add("active");
        this.$emit("requestClick", item);
      }
    },
    async getArticleCount(data) {    
        this.index = -1
        data.types = data.types + ',-1'
        let configMap = {
            "must": [
                {
                    "queryValue": data.paramStr,
                    "esField": "all"
                }
            ]
        }
        for (let key in data) {
            if (
                [
                    // 'articleTypes',
                    // 'types'
                ].includes(key) &&
                (data[key] || data[key] === 0)
            ) {
                let json = {
                    'articleTypes':'articleType',
                    'types':'type'
                };
                configMap.must.push({
                    queryValue: ['articleTypes','types'].includes(key) ? (data[key]+'').split(',') : data[key],
                    esField: json[key],
                });
            }
        }
        this.loadingFlag = true
        const resp = await this.$apis.searchServe.getTypeCount({
            configMap:configMap,
            otherQueryParams: {
                platformId: 3,
            },
        });
        this.loadingFlag = false
        this.achievementList = resp.data.data.filter(item=>{
            return item.valueName
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.side-box-container {
  width: 260px;
  //   height: 390px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  padding: 35px 30px;
  position: relative;
  margin-bottom: 30px;
  user-select: none;
  &::after {
    position: absolute;
    left: -1px;
    top: -1px;
    content: "";
    display: block;
    width: 260px;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    font-size: 22px;
    font-weight: 400;
    color: #00a4ff;
    border-bottom: 28px;
  }
  .box {
    font-size: 18px;
    color: #333333;
    margin-top: 15px;
    cursor: pointer;
    &.active {
      color: #00a4ff;
    }
    .num {
      margin-left: 10px;
    }
  }
}
</style>
